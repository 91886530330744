<template>
	<div>
		Assignments
	</div>
</template>

<script>
export default {
	name: "Assignments"
};
</script>

<style scoped></style>
